$(document).ready(function () {
  const $body = $('body');

  let owl = $('.owl-carousel').owlCarousel({
    margin: 15,
    loop: false,
    autoWidth: true,
    items: 4,
    dots: false,
    autoplay: false,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    lazyLoad: true
  });

  $('#fullpage').fullpage({
    navigation: false,
    fixedElements: 'header',
    menu: '.main-menu',
    anchors: [
      'firstPage',
      'whyPlayamoPartners',
      'bob',
      'playAmo',
      'betchan',
      'spinia',
      'betamo',
      'chan',
      'cookie',
      'woo',
      '20bet',
      'avalon78',
      'masonslots',
      'national',
      'bizzo',
      'aboutUs'
    ],
    responsiveWidth: 1200,
    responsiveHeight: 700,
    lazyLoading: false,
    afterLoad: function (anchorLink) {
      if (anchorLink === 'whyPlayamoPartners') {
        $('.advantages__item').addClass('advantages__item--active');
      }
      if (anchorLink === 'aboutUs') {
        owl.trigger('play.owl.autoplay', [2000]);
      }
    },
    onLeave: function (index) {
      if (index === 2) {
        $('.advantages__item').removeClass('advantages__item--active');
      }
    }
  });
  $('#instructions').fullpage({
    navigation: false,
    fixedElements: 'header',
    menu: '.main-menu',
    anchors: [
      'step1',
      'step2',
      'step3',
      'step4',
      'statusChecking',
      'filters',
      'registeredCustomers'
    ],
    responsiveWidth: 1200,
    responsiveHeight: 700,
    lazyLoading: false
  });

  $('.terms__content').niceScroll('.terms__content-wrap', {
    cursorcolor: '#00a188',
    cursorwidth: '10px',
    background: '#f1f1f1',
    cursorborder: 'none',
    cursorborderradius: '6px',
    autohidemode: 'true'
  });

  if (window.location.hash === '#terms-and-conditions') {
    $('#terms').modal({
      fadeDuration: 250,
      showClose: false
    });
  }

  $('.modal-open').click(function (e) {
    e.preventDefault();
    const href = $(this).attr('href').split('#');
    let link = $(this).attr('href', `#${href[1]}`);
    link.modal({
      fadeDuration: 250,
      showClose: false
    });
  });

  $body.on($.modal.OPEN, function () {
    $.fn.fullpage.setAllowScrolling(false);
    $('#ascrail2000').css('opacity', '1');
    $('.terms__content').getNiceScroll().resize();
  });
  $body.on($.modal.CLOSE, function () {
    $.fn.fullpage.setAllowScrolling(true);
    $('#ascrail2000').css('opacity', '0');
  });

  $('.header__mob').on('click', function () {
    $(this).toggleClass('opened');
    $('.mobile-menu').toggleClass('opened');
  });
  $('.mobile-menu__close').on('click', function () {
    $('.header__mob').removeClass('opened');
    $('.mobile-menu').removeClass('opened');
  });
  $('.mobile-menu__link, .dropdown-menu-mob__link').on('click', function () {
    $('.header__mob').removeClass('opened');
    $('.mobile-menu').removeClass('opened');
  });
});

