import { locales } from "/src/data/site";

const browserLangDetected = window.navigator.language.slice(0, 2);
const browserLang = browserLangDetected === 'zh' ? 'cn' : browserLangDetected;
const currentLang = document.getElementsByTagName('html')[0].getAttribute('lang');
const savedLang = localStorage.getItem('lang');

const redirect = (lang) => {
  const pathname = window.location.pathname;
  const hash = window.location.hash;
  const LOCALE_REG_EXP = '[a-zA-Z]{2}';
  const PAGE_REG_EXP = '[a-zA-Z0-9_-]+';
  const found = pathname.match(
    new RegExp(`^\/((${LOCALE_REG_EXP})\/(${PAGE_REG_EXP})|(${LOCALE_REG_EXP})|(${PAGE_REG_EXP})?)([\?#\/]|$)`),
  );
  const pagePath = found?.[3] || found?.[5];

  window.location.href = `/${lang}/${pagePath ? pagePath + '/' : '' }${hash || ''}`
}
const setDefaultLang = () => {
  localStorage.setItem('lang', 'en');
}
const setLang = (lang, isRedirect) => {
  if (locales.find(existingLang => existingLang === lang)) {
    localStorage.setItem('lang', lang);
    isRedirect ? redirect(lang) : null
  } else {
    setDefaultLang()
  }
}

if (!savedLang) {
  if (currentLang !== 'en') {
    setLang(currentLang, currentLang !== browserLang)
  } else if (currentLang === 'en' && currentLang !== browserLang) {
    setLang(browserLang, true)
  } else {
    setDefaultLang()
  }
}
