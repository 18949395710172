$(document).ready(function () {
  $('.dropdown-btn-js').on('click', (e) => {
    $(e.currentTarget).closest('.dropdown-js').toggleClass('open');
  });
  $(document).click(function (e) {
    $('.dropdown-js')
      .not($('.dropdown-js').has($(e.target)))
      .removeClass('open');
  });
});
